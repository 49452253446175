<template>
  <form v-on:submit.prevent="submitQuery" class="acuspire-search-container container-fluid">
      <div :class="subsystem === 'joboffer' ? 'joboffer-search-bar' :'row'">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4" :class="subsystem === 'joboffer' && 'joboffer-search-job-wrap'">
          <div class="acuspire-search-wrap">
            <input
              v-model="jobTitleVal"
              class="acuspire-search acuspire-search-text search-job"
              :class="subsystem === 'joboffer' && 'joboffer-search-job'"
              type="text"
              size="30"
              maxlength="50"
              :placeholder="$t('searchBar.jobSearch')"
            />
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4" :class="subsystem === 'joboffer' && 'joboffer-search-city-wrap'">
          <div class="acuspire-search-wrap">
            <input
              v-model="cityVal"
              class="acuspire-search acuspire-search-text search-city"
              :class="subsystem === 'joboffer' && 'joboffer-search-city'"
              type="text"
              size="30"
              maxlength="50"
              :placeholder="$t('searchBar.location')"
            />
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4" :class="subsystem === 'joboffer' && 'joboffer-btn-search-wrap'">
          <button class="acuspire-search acuspire-btn-search" type="submit" title="search">
            <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <svg-component
                class="job-search-icon"
                v-if="subsystem === 'joboffer' && !isLoading"
                width="14px"
                height="14px"
                viewBox="0 0 512 512"
                d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"
            />
            <span v-else-if="!isLoading" >
            {{ $t("searchBar.search") }}
            </span>
          </button>
        </div>
      </div>
  </form>
</template>

<script>

import {mapActions, mapState, mapMutations} from "vuex";
import SvgComponent from "./SvgComponent";

export default {
  components: {SvgComponent},
  props: ['isLoading'],
  computed:{
    ...mapState(['pageReferrer']),
    ...mapState('jobs',["jobTitle", "city"]),
    ...mapState( ["subsystem"]),
    jobTitleVal:{
      get(){
        return this.jobTitle;
      },
      set(value){
        this.jobTitleStatus(value);
      }
    },
    cityVal:{
      get(){
        return this.city;
      },
      set(value){
        this.cityStatus(value);
      }
    }
  },

  methods: {
    ...mapMutations(["pageReferrerStatus"]),
    ...mapMutations('jobs', ["cityStatus", "jobTitleStatus"]),
    ...mapActions('jobs', ['query']),
    submitQuery() {
      if (this.jobTitleVal === "" && this.cityVal === "") return;
      const loading = true;
      this.query({loading, jobQuery: this.jobTitleVal, cityQuery: this.cityVal})
    }
  },
  watch: {
    jobTitle(val, oldVal) {
      if (val === oldVal || this?.jobTitleVal === val) return;
      if(!this) return;
      this.jobTitleVal = val;
      // this.submitQuery();
    },
    city(val, oldVal) {
      if (val === oldVal || this?.cityVal === val) return;
      if(!this) return;
      this.cityVal = val;
      // this.submitQuery();
    },
    '$route.query.jobtitle_s': function(jobtitle_search) {
      if (!this || !jobtitle_search) return;
      this.jobTitleVal = jobtitle_search;
    },
    '$route.query.city_s': function(city_search) {
      if (!this || !city_search) return;
      this.cityVal = city_search;
    }
  },
  mounted() {
    this.pageReferrerStatus();
  }
};
</script>

<style lang="scss">
.joboffer-btn-search{
  color: #fff !important;
  border-radius: 0.5rem;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding: 6px 12px;
  border: 0;
  height: 42px;
  width: 20%;
}
</style>
