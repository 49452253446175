import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueI18n from "vue-i18n";
import "document-register-element/build/document-register-element";
import vueCustomElement from "vue-custom-element";
import store from './store';
import JobBlock from "@/components/JobBlock.vue";
import Designations from "@/components/Designations.vue";

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(vueCustomElement);

function loadLocaleMessages() {
    const locales = require.context(
        "./locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
        );
        const messages = {};
        locales.keys().forEach((key) => {
            const matched = key.match(/([A-Za-z0-9-_]+)\./i);
            if (matched && matched.length > 1) {
                const locale = matched[1];
                messages[locale] = locales(key);
        }
    });
    return messages;
}

const i18n = new VueI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: loadLocaleMessages(),
});

App.router = router;
App.i18n = i18n;
App.store = store;

Vue.customElement("acuspire-job-widget", App, {
    props: ["type"]
});

JobBlock.i18n = i18n;
Designations.i18n = i18n;
Designations.store = store;

Vue.customElement('acuspire-job-block', JobBlock, {
    props: [
      'job-board-widget-url',  // (string?) the url to the page with the acuspire-job-widget
      'system-code', // (string) the system code for the job board
      'lang', // (string?) the language of the job board ('en' or 'fr')
      'header-text', // (string?) the text to display in the header
      'searchable',  // (boolean?) whether the job board is searchable
      'keywords', // (string?) the keywords to use to search for
      'jobs-to-display', // (number?) the number of jobs to display
    ]
});

Vue.customElement('acuspire-designations', Designations, {
    props: [
      'job-board-widget-url',  // (string?) the url to the page with the acuspire-job-widget
      'system-code', // (string) the system code for the job board
      'lang', // (string?) the language of the job board ('en' or 'fr')
      'header-text', // (string?) the text to display in the header
    ]
});