<template>
    <div class="job-item" @mouseenter="beginHover" @mouseleave="endHover">
      <div class="job-text-row">
        <a :href="link" :title="jobtitle" class="jobtitle" target="_blank" @click="setViewed">{{ jobtitle }}</a>
      </div>
      <div class="job-text-row" :title="[company, location].join(' ')">
        <span class="company">{{ company }}</span>
        &nbsp;<span class="location">{{ remote === 1 ? $t('jobBoard.remote') : location }}</span>
      </div>
    </div>
</template>

<script>
import JobViewTracking from "@/composables/JobViewTracking";
export default {
  props: {
    'jobtitle': {
      type: String,
      required: false
    },
    'company': {
      type: String,
      required: false
    },
    'location': {
      type: String,
      required: false
    },
    'remote': {
      type: Number,
      required: false
    },
    'link': {
      type: String,
      required: false
    },
    'created_at': {
      type: Date,
      required: false
    },
    'job_id': {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      hoverStart: null
    };
  },
  computed: {
    formatted_created_at() {
      return this?.created_at?.toLocaleDateString() ?? null;
    }
  },
  methods: {
    setViewed() {
      this.job_id && JobViewTracking.viewed(this.job_id)
    },
    beginHover() {
      this.hoverStart = performance.now();
    },
    endHover() {
      if (this.hoverStart !== null) {
        const hoverDuration = performance.now() - this.hoverStart;
        if (hoverDuration > 1000) {
          // hovered for 1s, so set hovered on this job_id
          this.job_id && JobViewTracking.hovered(this.job_id);
        }
        this.hoverStart = null;
      }
    }
  }
}

</script>

<style scoped lang="scss">
a {
  text-decoration: underline;
  font-weight: bold;
  color: var(--acuspire-job-block-link-colour, darkgreen);
}
.job-item + .job-item {
  margin-top: 1.25em;
}

.job-text-row {
  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
  line-height: 1.25;
  + span {
    line-height: 1.25;
  }
}
</style>