<template>
  <div class="acuspire-job-block" style="">
    <div class="acuspire-job-block-above" :class="searchable ? ['has-search'] : []">
      <h4 v-if="headerText">{{ headerText }}</h4>
      <!-- <button @click="getJobs">Test</button>-->
      <div class="jobs-list">
        <template v-if="loading">
          <skeleton-job-item v-for="i in jobsToDisplay" :key="i" />
        </template>
        <template v-else>
        <job-item
          v-for="job in jobs"
          :jobtitle="job.jobtitle"
          :company="job.company"
          :location="job.location"
          :remote="job.remote"
          :link="job.link"
          :key="job.job_id || job.email_code"
          :created_at="job.created_at"
          :job_id="job.job_id"
        />
        </template>
      </div>
    </div>
<!--    <form @submit.prevent="openSearchTab()">-->
    <form target="_blank" method="get" :action="searchURL">
    <div v-if=searchable class="acuspire-job-block-below acuspire-search-wrap">

      <input v-model="query"  class="acuspire-search acuspire-search-text search-job" type="search" :placeholder="$t('searchBar.jobSearch')">
      <input v-model="city" class="acuspire-search acuspire-search-text search-city" type="text" :placeholder="$t('searchBar.location')">
        <button type="submit" class="acuspire-search acuspire-btn-search" style="flex: 1;">
        {{$t('searchBar.search')}}
        </button>
    </div>
    </form>
  </div>
</template>

<script>
import JobItem from "@/components/JobBlock/JobItem.vue";
import SkeletonJobItem from "@/components/JobBlock/SkeletonJobItem.vue";
import http from '@/http-common';
import JobViewTracking from '@/composables/JobViewTracking';

export default {
  components: { JobItem, SkeletonJobItem },
  props: {
    jobBoardWidgetUrl: {
      type: String,
      required: false
    },
    systemCode: {
      type: String,
      required: false
    },
    lang: {
      type: String,
      required: false
    },
    headerText: {
      type: String,
      required: false
    },
    searchable: {
      type: Boolean,
      required: false
    },
    keywords: {
      type: String,
      required: false
    },
    weightedSearchTerms: {
      type: Object,
      required: false
    },
    rankWeights: {
      type: Object,
      required: false
    },
    jobsToDisplay: {
      type: Number,
      required: false
    },
    limitToSubsystem: {
      type: Boolean,
      required: false,
      default: false,
    },
    includePreferredSubsystemJobs: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Amounts to derank jobs by.
     * @type {{seen: number, applied: number, viewed: number, hovered: number} | undefined}
     */
    derankEventWeights: {
      type: Object,
      required: false,
    },
  },
  data() {
      return {
        loading: true,
        jobs: [
        ],
        query: '',
        city: '',
      }
    },
  async mounted() {
    this.$i18n.locale = this.lang === 'fr' ? 'fr' : 'en';
    // fetch jobs
    await this.getJobs();
  },
  methods: {
      openSearchTab() {
        window.open(this.searchURL, '_blank').focus();
      },
      async getJobs() {
        let terms;
        if (this.keywords && typeof this.keywords === 'string') {
          terms = this.keywords.split(' ').reduce((acc, term) => {
            acc[term] = 1;
            return acc;
          }, {})
        }
        terms = {...terms, ...(this.weightedSearchTerms ?? {})};
        this.loading = true;
        const {derank, similar} = JobViewTracking.results(this?.derankEventWeights ?? {});
        const json = await http.post('/search/widget', {
          subsystem: this.systemCode,
          q: {terms},
          limit: this.jobsToDisplay,
          lang: this.lang ?? undefined,
          similarJobs: similar,
          derankJobs: derank,
          rankWeights: this.rankWeights ?? undefined,
          limitToSubsystem: this.limitToSubsystem,
          includePreferredSubsystemJobs: this.includePreferredSubsystemJobs,
        });
        this.jobs = (json?.data?.jobs ?? []).map(job => {
          return {
            jobtitle: job.jobtitle,
            company: job.company,
            location: [job.city, job.state].join(', '),
            link: this.jobBoardWidgetUrl + '#/job/' + job.email_code,
            job_id: job.job_id,
            created_at: new Date(Date.parse(job.created_at)),
          }
        });
        // TODO (future) remove this and add seen on the job item itself (using IntersectionObserver)
        this.jobs.forEach(job => {
          JobViewTracking.seen(job?.job_id);
        })
        this.loading = false;
      }
    },
  watch: {
    jobsToDisplay: 'getJobs',
    lang: 'getJobs',
  },
  computed: {
    searchURL() {
      const buildURLQuery = obj =>
        Object.entries(obj)
          .map(pair => pair.map(encodeURIComponent).join('='))
          .join('&');
      const params = {
        jobtitle_s: this.query,
        city_s: this.city,
      }
      const searchParams = buildURLQuery(params);
      return this.jobBoardWidgetUrl + '#/?' + searchParams;
    },
  }
};
</script>


<style scoped lang="scss">
/* Styles are here to prevent overrides */
.acuspire-job-block {
  /* Start Bootstrap */
  a {
    background-color: transparent;
  }

  a:active, a:hover {
    outline: 0;
  }

  img {
    border: 0;
  }

  button, input {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  button {
    overflow: visible;
  }

  button {
    text-transform: none;
  }

  button {
    -webkit-appearance: button;
    cursor: pointer;
  }

  button[disabled] {
    cursor: default;
  }

  button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  input {
    line-height: normal;
  }

  @media print {
    *, :after, :before {
      color: #000 !important;
      text-shadow: none !important;
      background: 0 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
    a, a:visited {
      text-decoration: underline;
    }
    a[href]:after {
      content: " (" attr(href) ")";
    }
    a[href^="#"]:after {
      content: "";
    }
    img {
      page-break-inside: avoid;
    }
    img {
      max-width: 100% !important;
    }
    h3, p {
      orphans: 3;
      widows: 3;
    }
    h3 {
      page-break-after: avoid;
    }
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  :after, :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
  }

  button, input {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  a {
    color: #337ab7;
    text-decoration: none;
  }

  a:focus, a:hover {
    color: #23527c;
    text-decoration: underline;
  }

  a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  img {
    vertical-align: middle;
  }

  h3, h5 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h5 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 24px;
  }

  h5 {
    font-size: 14px;
  }

  p {
    margin: 0 0 10px;
  }

  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .row {
    margin-right: -15px;
    margin-left: -15px;
  }

  .col-md-4, .col-md-6, .col-md-8, .col-sm-12, .col-sm-5, .col-sm-7, .col-xs-12, .col-xs-4, .col-xs-8 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-xs-12, .col-xs-4, .col-xs-8 {
    float: left;
  }

  .col-xs-12 {
    width: 100%;
  }

  .col-xs-8 {
    width: 66.66666667%;
  }

  .col-xs-4 {
    width: 33.33333333%;
  }

  @media (min-width: 1200px) {
    .col-lg-3 {
      width: 25% !important
    }
    .col-lg-4 {
      width: 33.33333333% !important;
    }
    .col-lg-6 {
      width: 50% !important;
    }
    .col-lg-9 {
      width: 75% !important
    }
  }
  @media (min-width: 768px) {
    .col-sm-12, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7 {
      float: left;
    }
    .col-sm-7 {
      width: 58.33333333%;
    }
    .col-sm-6 {
      width: 50%;
    }
    .col-sm-5 {
      width: 41.66666667%;
    }
    .col-sm-4 {
      width: 33.33333333%;
    }
    .col-sm-12 {
      width: 100%;
    }
  }
  @media (min-width: 992px) {
    .col-md-4, .col-md-6, .col-md-8 {
      float: left;
    }
    .col-md-8 {
      width: 66.66666667%;
    }
    .col-md-6 {
      width: 50%;
    }
    .col-md-4 {
      width: 33.33333333%;
    }
  }

  @media(max-width: 767px) {
    .acuspire-job-meta-apply {
      display: flex;
      flex-direction: column-reverse;
      margin: 10px 0;
    }
  }

  .container-fluid:after, .container-fluid:before, .row:after, .row:before {
    display: table;
    content: " ";
  }

  .container-fluid:after, .row:after {
    clear: both;
  }

  /* End Bootstrap */
}


.acuspire-job-block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--acuspire-job-block-border-colour, var(--acuspire-job-block-primary-colour, darkgreen));
  border-radius: var(--acuspire-job-block-border-radius, 10px);

  .acuspire-search-text {
    border-radius: 0.5rem;
    height: 2.625em;
    padding-left: 0.625em;
    border: 1px solid #d6d6d6;
    width: 17.5em;
    //max-width: 300px;
    min-width: 12.5em;
    font-family: "Lato", "Arial", "Verdana", sans-serif;
    flex: 1 1 auto;
    outline-color: var(--acuspire-job-block-outline-colour, var(--acuspire-job-block-primary-colour, darkgreen));
    outline-offset: 0;
  }

  .acuspire-btn-search {
    color: var(--acuspire-job-block-btn-text-colour, white);
    border-radius: 3px;
    height: 2.625em;
    padding: 0.375em 0.75em;
    // font-size: 14px;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    border: 1px solid var(--acuspire-job-block-btn-primary-colour, var(--acuspire-job-block-primary-colour, darkgreen));
    width: 100%;
    min-width: fit-content;
    //max-width: 300px;
    background-color: var(--acuspire-job-block-btn-primary-colour, var(--acuspire-job-block-primary-colour, darkgreen));
    font-family: "Lato", "Arial", "Verdana", sans-serif;
    flex: 1;
    white-space: nowrap;
    &:hover {
      background-color: var(--acuspire-job-block-btn-hover-colour, var(--acuspire-job-block-secondary-colour, white));
      color: var(--acuspire-job-block-btn-text-hover-colour, var(--acuspire-job-block-primary-colour, darkgreen));
      border-color: var(--acuspire-job-block-btn-text-hover-colour, var(--acuspire-job-block-primary-colour, darkgreen));;
    }
  }
}

.acuspire-job-block-above {
  margin: 1.5em;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
  h4 {
    margin: 0 0 1.25rem;
    font-size: 1.5em;
    font-weight: 800;
  }

  .jobs-list {
    flex: 1;
    overflow: auto;
    height: 100%;
    min-height: 40px;
  }
}

.acuspire-job-block-above.has-search{
  border-bottom: 2px solid var(--acuspire-job-block-border-colour, var(--acuspire-job-block-primary-colour, darkgreen));
  padding: 0 0 1.5em 0;
  margin-bottom: 0;
}

.acuspire-job-block-below {
  margin: 1.25em 1.5em;
  display: flex;
  flex-wrap: wrap;
  gap: 0.25em 1em;
  max-width: 48em;
  flex: 0;
}

</style>