<script>
export default {
  name: "SkeletonJobItem",
  data() {
    const jobTitleWidthRange = [10, 15];
    const jobLocationWidthRange = [12, 20];
    const getNumberFromRange = (range) => range[0] + (Math.random() * (range[1] - range[0]));
    return {
      jobTitleWidth: getNumberFromRange(jobTitleWidthRange),
      jobLocationWidth: getNumberFromRange(jobLocationWidthRange),
    }
  },
};
</script>

<template>
  <div class="job-item">
    <div class="job-text-row" :style="{width: jobTitleWidth + 'em'}">
      <span class="skeleton-box">
      </span>
    </div>
    <div class="job-text-row" :style="{width: jobLocationWidth + 'em'}">
      <span class="skeleton-box">
      </span>
    </div>
  </div>

</template>

<style scoped lang="scss">

.skeleton-box {
  background-color: #e0e0e0;
  border-radius: 5px;
  //margin: 10px 0;
  //padding: 10px;
  height: 100%;
  width: 100%;
  position: relative;
  display: inline-block;
  animation: color 2s linear infinite;
}

@keyframes color {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #eaeaea;
  }
  100% {
    background-color: #e0e0e0;
  }
}

// make it shimmer
.skeleton-box::before {
  content: "";
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
  animation: shimmer 1000ms linear infinite;
}

@keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
.job-item {
  height: 2.5em;
}
.job-item + .job-item {
  margin-top: 1.25em;
}

.job-text-row {
  width: 100%;
  max-width: 100%;
  height: 1.125em;
  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
  line-height: 1.25;
  + span {
    line-height: 1.25;
  }
  margin-bottom: 2px;
  border-radius: 100px;
}


</style>