import Vue from "vue";
import VueRouter from "vue-router";
import JobBoard from "../views/JobBoard.vue";
import JobDescription from "../views/JobDescription.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Job-Board",
        component: JobBoard,
        props: true,
    },
    {
        path: "/job/:emailCode",
        name: "Job-Description",
        component: JobDescription,
        props: true,
    },
];

const router = new VueRouter({
    routes,
    // Always return scroll position to top left when navigating to a new route
    // This function is fired on page load, so we initialize the widget position here
    scrollBehavior (to, from,savedPosition) {
        if (to?.params?.emailCodeToScrollTo) {
            return;
        }
        if(savedPosition){
            // TODO: Long scrolls up/down are extremely annoying to users and so this has been commented out.
            //return {savedPosition, behavior: 'smooth'}
            return {savedPosition}
        }else{
            // Set Y position of acuspire widget.
            window.acuspireWidgetLoaded = true;
            const container = document.querySelector("#acuspire-jobs-widget");
            let rect = container?.getBoundingClientRect();
            if (!rect?.top) return;
            window.acuspireWidgetTop = rect.top + window.scrollY - 200;
        }
    }
});

export default router;
