<template>
  <div :class="subsystem === 'joboffer' ? 'joboffer-job-widget-pagination':'acuspire-pagination-container'">
    <button v-if="activePage > 0"
      @click="prevPage"
      class="acuspire-paginate acuspire-previous"
    >
      Previous
    </button>
      <button
        v-for="index in displayPages"
        :key="index"
        @click="chosePage(index)"
        :class="checkActivePage(index)"
        :disabled="checkActivePageDisabled(index)"
        class="acuspire-paginate"
      >
        {{ index + 1 }}
      </button>
    <button v-if="activePage + 1 < numPages"
      :class="!this.checkNextPage()"
      @click="nextPage"
      class="acuspire-paginate acuspire-next"
    >
      Next
    </button>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "Paginate",

  props: ["totalRangeResults", "totalResults", "activePage"],

  data() {
    return {
      numPages: null,
      displayPages: [],
      minimumResult: 25,
      initialDisplayLength: 7,
      middleIndex: 3
    };
  },

  mounted() {
    this.setNumOfPages();
    this.setDisplayPages();
  },

  computed:{
    ...mapState(['subsystem']),
  },

  watch:{
    activePage: function(val, oldVal){
      if (val === oldVal) return;
      this.setDisplayPages();
    }
  },

  methods: {
    ...mapActions('jobs', ['movePage', 'chosenPage']),
    checkNextPage() {
      return this.activePage < Math.ceil(this.totalResults / 25)-1;
    },

    checkPrevPage() {
      return this.activePage > 0;
    },

    checkActivePage(index) {
      if (this.activePage === index) return "acuspire-active";
    },

    checkActivePageDisabled(index) {
      if (this.activePage === index) return true;
    },

    nextPage() {
      if (this.checkNextPage()) this.movePage( "next");
    },

    prevPage() {
      if (this.checkPrevPage()) this.movePage( "prev");
    },

    chosePage(index) {
      if (this.activePage === index) return;
      const returned = false;
      this.chosenPage({index, returned});
    },

    setNumOfPages() {
      this.numPages = Math.ceil(this.totalResults / this.minimumResult); // 100 / 25 = 4 pages
    },

    setDisplayPages() {
      if (this.numPages > this.initialDisplayLength) {
        // Initial
        this.displayPages = [...Array(this.initialDisplayLength).keys()];

        // Chosen pages
        if (this.activePage > 5) {
          const leftPageList = this.setLeftJustifiedPages();
          const rightPageList = this.setRightJustifiedPages();

          this.displayPages = leftPageList.concat(rightPageList);
        }
      } else {
        this.displayPages = [...Array(this.numPages).keys()];
      }
    },

    setLeftJustifiedPages() {
      let index = this.middleIndex;
      const leftPageList = [this.activePage];
      for (let i = this.activePage - 1; index > 0; i--) {
        leftPageList.unshift(i);
        index--;
      }
      return leftPageList;
    },

    setRightJustifiedPages() {
      let index = this.middleIndex;
      const rightPageList = [];

      // Determine how much left to iterate
      const remainingPages = this.numPages - this.activePage - 1;
      const remainingIndex = remainingPages + index;
      const pageLimit = remainingIndex < 6 ? remainingIndex : 6;

      for (let i = this.activePage + 1; index < pageLimit; i++) {
        rightPageList.push(i);
        index++;
      }

      return rightPageList;
    }
  }
};
</script>
