<template>
  <svg xmlns="http://www.w3.org/2000/svg" :viewBox="viewBox" :width="width" :height="height" class="svg-icon">
    <path :d="d" :fill="color"/>
  </svg>
</template>

<script>
export default{
  name: "SvgComponent",
  props: {
    viewBox : {
      type : String,
      default : '0 0 20 20',
    },
    color : {
      type : String
    },
    d: {
      type : String,
      default : '',
    },
    width : {
      type : String,
      default : '20'
    },
    height : {
      type : String,
      default : '20'
    }
  }
}
</script>
