import http from "../../http-common";

export default{
    namespaced: true,
    state: {
        isLoading: true,
        totalRangeResults: "",
        totalResults: 0,
        displayCurrResults: "",
        displayRangeResults: "",
        jobs: [],
        meta: {},
        designationsQuery: "",
        designationResults: "",
        jobTitle: "",
        city: "",
        searched: false,
        startQueryNum: 0,
        activePage: 0,
        returned: false,
        URL: '',
        designationsURL: '',
        showJobList: "",
    },
    mutations:{
        numberWithCommas(state,{total_results} ) {
            state.displayRangeResults = total_results.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        URL(state) {
            // get the params from the state (subsystem comes from top level vuex somehow?)
            const params = {
                subsystem_code: this?.state?.subsystem ?? undefined,
                q: state?.jobTitle ?? undefined,
                city: state?.city ?? undefined,
                page: state?.activePage ?? undefined,
            };
            // remove undefined/non-string/int and create the encoded params string
            const encodedParams = Object.entries(params)
                .filter(([, value]) => (
                  value !== undefined &&
                  (typeof value === 'string' || typeof value === 'number') &&
                  value !== ''
                ))
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join('&');
            state.URL = `/search?${encodedParams}`;
        },
        designationsURL(state) {
            const subsystem = this?.state?.subsystem;
            const designationsQuery = state?.designationsQuery;

            const params = {
                subsystem_code: subsystem,
                [subsystem === 'eco' ? 'ep_code' : 'designationsQuery']: designationsQuery
            };

            const encodedParams = Object.entries(params)
                .filter(([, value]) => value != null && value !== '')
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join('&');

            const baseUrl = subsystem === 'eco' ? '/eco/certification-search' : '/certification-search';
            state.designationsURL = `${baseUrl}?${encodedParams}`;
        },
        loadingStatus(state, newLoadingStatus){
            state.isLoading = newLoadingStatus;
        },
        cityStatus(state, newCity){
            state.city = newCity;
        },
        jobTitleStatus(state, newJobTitle){
            state.jobTitle = newJobTitle;
        },
        designationStatus(state, newDesignation){
            state.designationsQuery = newDesignation;
        },
        jobListSubSystem(state, subsystemData){
            //TODO: Move away from hard-coding this and set a show_job_list value in the subsystems table that can be returned by the careers.joboffer.ca endpoint
            var subsystems = ['eco','collegejobs','fpdi','jobsforukraine','pixels','powerjobs'];

            state.showJobList = false;

            for(var i=0; i<subsystems.length; i++)
            {
                if(subsystems[i] === subsystemData.subsystem)
                {
                    state.showJobList = true;
                }
            }
        }
    },
    methods: {
    },
    actions:{
        async getJobs({commit,state}) {
            try {
                commit('URL');
                const json = await http.get(state.URL);
                state.totalResults = json.data.total_results;
                state.jobs = json.data.jobs;
                state.meta = json.data.meta_config;

                if (state.totalResults > 0)
                    state.totalRangeResults = `${json.data.result_set} of ${json.data.total_results}`;

                state.displayCurrResults = json.data.result_set;
                commit('numberWithCommas', {total_results: json.data.total_results});
                state.searched = true;
            } catch (error) {
                state.searched = true;
            }
        },
        async getDesignationsData({commit,state}) {
            try {
                commit('designationsURL');
                const json = await http.get(state.designationsURL);
                state.designationResults = [];
                if (json.data && json.data.valid) {
                    state.designationResults.push(json.data.data);
                }
                state.searched = true;
            } catch (error) {
                state.searched = true;
            }
        },
        async getJobData({dispatch, commit, state}, {performScroll} = {performScroll:true}) {
            commit('jobListSubSystem', {subsystem:this.state.subsystem})

            // The second condition is used to allow for a default search of internal jobs for subsystems
            // Currently hard-coded to specific subsystems for now (2022/10/29)
            if (
              state.jobTitle !== "" ||
              state.city !== "" ||
              (state.showJobList && state.jobTitle === "" && state.city === "")
            ) {

                // Prevent scroll from happening on first load (unless there were search params provided).
                if (!window.acuspireWidgetLoaded || (state.jobTitle || state.city)) {
                    if (performScroll) {
                        window.scrollTo(0, window.acuspireWidgetTop);
                    }
                    // TODO: Long scrolls up/down are extremely annoying to users and so this has been commented out.
                    // window.scrollTo({
                    //     top: window.acuspireWidgetTop,
                    //     left: 0,
                    //     behavior: 'smooth'
                    // });
                    // Set acuspireWidgetLoaded as false so the scroll works from the second render.
                } else {
                    window.acuspireWidgetLoaded = false;
                }
                await dispatch("getJobs");
            }

            commit('loadingStatus', false);
        },

        async getDesignations({dispatch, commit, state}) {
            if (state.designationsQuery) {
                await dispatch("getDesignationsData");
            }

            commit('loadingStatus', false);
        },
        async query({dispatch, commit, state}, {loading, jobQuery, cityQuery}) {
            commit('loadingStatus', loading);
            state.jobTitle = jobQuery ?? "";
            state.city = cityQuery ?? "";
            state.searched = false;

            if (!state.returned) {
                state.activePage = 0;
                state.startQueryNum = 0;
            }


            await dispatch('getJobData');
        },
        async queryDesignations({dispatch, state}, {designationsQuery}) {
            state.designationsQuery = designationsQuery ?? "";
            state.searched = false;
            await dispatch('getDesignations');
        },
        async checkReturnPage({dispatch, state}) {
            if (state.returnPage) await dispatch("chosenPage", {returnPage:state.returnPage, returned:true});
        },

        async movePage({dispatch,commit, state}, direction) {
            if (direction === "next") state.activePage++;
            else if (direction === "prev") state.activePage--;

            commit('loadingStatus', true);
            await dispatch('getJobData');
        },

        async chosenPage({dispatch,commit, state},{index=0, returned=false}) {
            state.activePage = index;
            state.returned = returned;
            commit('loadingStatus', true);

            if (!returned) await dispatch('getJobData');
        },
    },
}
