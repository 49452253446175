<script>
import { mapState } from "vuex";
export default {
    name: "Banner",
    props: {
      code: {
        type: String,
        required: true
      },
    },
    data() {
      return {
      };
    },
    computed:{
      languageCode() {
        return this.$props.code + (this.$i18n.locale === 'fr' ? '_fr' : '_en');
      },
      ...mapState( ["subsystem"]),
      ...mapState('subsystemData', {
        url(state) {return state.banners?.[this.languageCode]?.url;},
        href(state) {return state.banners?.[this.languageCode]?.href;},
        width(state) {return state.banners?.[this.languageCode]?.width;},
        height(state) {return state.banners?.[this.languageCode]?.height;},
        alt(state) {return state.banners?.[this.languageCode]?.alt;},
        bg_color(state) {return state.banners?.[this.languageCode]?.bg_color;},
      }),
    },
  };
</script>

<template>
  <div style="width: 100%">
    <a v-if="url"
       :href="href"
       style="width:100%;"
       target="_blank"
       rel="noopener nofollow noreferrer"
    >
      <div class="banner" :style="{backgroundColor: bg_color}">
        <img class="banner-img" :src="url" :width="width" :height="height" :alt="alt" />
      </div>
    </a>
  </div>
</template>

<style scoped lang="scss">
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 10px;
    width: 100%;
  }
  .banner-img {
    border-radius: 10px;
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
  }

</style>