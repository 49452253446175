import axios from 'axios';

let apiConfig = {
  headers: {
    'Content-type': 'application/json'
  }
};

if(process.env.NODE_ENV === 'production')
{
  apiConfig.baseURL = 'https://careers.joboffer.ca/api';
}
else if(process.env.NODE_ENV === 'staging')
{
  apiConfig.baseURL = 'https://jobs.acuspiretesting.com/api';
}
else
{
  apiConfig.baseURL = 'http://jobs.acuspire.loc:8000/api';
}

export default axios.create(apiConfig);
