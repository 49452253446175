<template>
  <div class="acuspire-job-container">
    <div v-if="job.logo" class="acuspire-job-logo-container">
      <img :src="job.logo" alt="job-logo">
    </div>
    <div class="acuspire-job-descr-container">
        <h3>
            <router-link
                :to="{'name': 'Job-Description' , params: {page: this.activePage, emailCode: job.email_code}}"
                class="acuspire-job-description"
                replace
                >{{ job.jobtitle }}
            </router-link>
          <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

        </h3>
        <h5>
          {{ job.company }}
          <span class="location">{{ job.remote === 1 ? $t('jobBoard.remote') : `${job.city}, ${job.state}` }}</span>
        </h5>
        <p class="posted-date"><small>Posted {{ formatDate(job.created_at) }}</small></p>
        <wage-tag :job="job"></wage-tag>
        <p>{{ job.snippet }}</p>
    </div>

    <div class="acuspire-job-meta-apply">
        <div class="job-description-apply">
            <router-link
                :to="{'name': 'Job-Description' , params: {page: this.activePage, emailCode: job.email_code}}"
                replace
            >
                <button class="acuspire-jobs-btn acuspire-btn-apply col-xs-12">
                  <span v-if="!isLoading"> {{ $t("job.apply") }}</span>
                  <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
            </router-link>
        </div>
        <div>
            <img
                v-if="isEP"
                :src="epImg"
                :title="epTitle"
                class="epImg"
                alt="EPL Certification"
            />
            <img
                v-if="isIntern && subsystem === 'eco'"
                :src="internImg"
                :title="internTitle"
                class="internImg"
                alt="Internship"
            />
            <img
                v-if="isCoop && subsystem === 'eco'"
                :src="coopImg"
                :title="coopTitle"
                class="coopImg"
                alt="Co-op"
            />
        </div>
    </div>
  </div>
</template>

<script>
import WageTag from "../components/WageTag.vue";
import {mapState} from "vuex";

export default {
  name: "Job",
  props: ["job", "meta", "activePage", 'isLoading'],
  components: { WageTag },
  data() {
    return {
      epImg: "",
      internImg: "",
      coopImg: "",
      epTitle: "",
      internTitle: "",
      coopTitle: "",
      imgHostingURL: "https://app.joboffer.com/", //logos
      isEP: false,
      isIntern: false,
      isCoop: false,
      code: '',
      theme: {
        btn: "",
        hover: "",
      },
    };
  },
  mounted() {
      this.setImageLinks();
  },
  computed:{
    ...mapState( ["subsystem"])
  },
  methods: {
    setImageLinks() {
      if (!this.meta.length) {
          return;
      }

      this.isEP = this.isIntern = this.isCoop = false;
      this.epImg = this.internImg = this.coopImg = '';
      this.epTitle = this.internTitle = this.coopTitle = '';

      if (this.job.ep_certification) {
        this.isEP = true;
        this.epImg = this.meta[0].meta_value;
        this.epTitle = this.meta[0].meta_title;
      }

      if (this.job.preapproved_wage_funded) {
        this.isIntern = true;
        this.internImg = this.meta[1].meta_value;
        this.internTitle = this.meta[1].meta_title;
      }

      if (this.job.preapproved_coop) {
        this.isCoop = true;
        this.coopImg = this.meta[2].meta_value;
        this.coopTitle = this.meta[2].meta_title;
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },
  },
  watch: {
      job() {
          this.setImageLinks();
      }
  }
};
</script>
<style lang="scss">
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.spinner-border {
  display: inline-block;
  width: 17px;
  height: 17px;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;
}

@keyframes spinner-border {
  to { transform: rotate(360deg) #{"/* rtl:ignore */"}; }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.acuspire-job-logo-container{
  max-height: 75px;
  min-height: 50px;

  max-width: 75px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  img{
    width: 100%;
  }
}
</style>
