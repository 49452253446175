<template>
  <div>
    <div v-if="isNewLoading" class="small-loader"></div>

    <div v-else id="acuspire-job-description" class="container-fluid" :style="subsystemStyles">
        <div class="job-descr">
            <div v-if="!loadErrorInfo" style="margin-bottom: 50px">
                <div :class="subsystem === 'joboffer' && 'joboffer-description'" class="description">
                    <div class="job-card-wrapper">
                      <div class="job-card-inner-wrapper">
                          <div class="job-card-inner-top">
                            <router-link
                              :to="{'name': 'Job-Board' , params: {returnPage: this.$props.page, emailCodeToScrollTo: this.emailCode}}"
                              replace
                              tag="button"
                              class="back-button"
                              :class="subsystem === 'joboffer' && 'joboffer-go-back'"
                              >
                              <svg-component
                                  width="12px"
                                  height="12px"
                                  color="#fff"
                                  view-box="0 0 448 512"
                                  d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"
                              />
                            </router-link>
                              <div class="job-card-top-section">
                                <div class="job-title-posted-time-apply-button">
                                  <div class="job-title-posted-time">
                                    <img v-if="jobInfo.logo" :src="jobInfo.logo" alt="job-logo" class="job-logo">
                                    <span class="job-title">{{ jobInfo.jobtitle }}</span>
                                    <span class="posted-job-time svg-and-text">
                                       <svg-component
                                           width="12px"
                                           height="12px"
                                           color="#8492a6"
                                           view-box="0 0 512 512"
                                           d="M256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512zM232 256C232 264 236 271.5 242.7 275.1L338.7 339.1C349.7 347.3 364.6 344.3 371.1 333.3C379.3 322.3 376.3 307.4 365.3 300L280 243.2V120C280 106.7 269.3 96 255.1 96C242.7 96 231.1 106.7 231.1 120L232 256z"
                                       />
                                      <span>Posted {{ postedAtInfo }}</span>
                                      </span>
                                  </div>
                                  <a :href="applyUrlInfo"
                                     @click="clickedApply"
                                     class="acuspire-btn-apply first-apply job-desc-apply-button"
                                     :class="subsystem === 'joboffer' && 'joboffer-btn-apply'">
                                    <span>{{ $t("jobDescription.applyVia") }}</span>
                                  </a>
                                </div>
                                <div class="job-company">{{ jobInfo.company }}</div>
                              </div>
                          </div>
                          <div class="job-card-summary-section">
                              <div v-if="jobInfo.employment_type" class=" svg-and-text">
                                <svg-component
                                  width="13px"
                                  height="13px"
                                  color="#8492a6"
                                  view-box="0 0 512 512"
                                  d="M320 336c0 8.844-7.156 16-16 16h-96C199.2 352 192 344.8 192 336V288H0v144C0 457.6 22.41 480 48 480h416c25.59 0 48-22.41 48-48V288h-192V336zM464 96H384V48C384 22.41 361.6 0 336 0h-160C150.4 0 128 22.41 128 48V96H48C22.41 96 0 118.4 0 144V256h512V144C512 118.4 489.6 96 464 96zM336 96h-160V48h160V96z"
                                />
                                <span>{{ jobInfo.employment_type }}</span>
                              </div>
                              <div v-if="jobInfo.address" class=" svg-and-text">
                                <svg-component
                                    width="13px"
                                    height="13px"
                                    color="#8492a6"
                                    viewBox="0 0 384 512"
                                    d="M336 0C362.5 0 384 21.49 384 48V464C384 490.5 362.5 512 336 512H240V432C240 405.5 218.5 384 192 384C165.5 384 144 405.5 144 432V512H48C21.49 512 0 490.5 0 464V48C0 21.49 21.49 0 48 0H336zM64 272C64 280.8 71.16 288 80 288H112C120.8 288 128 280.8 128 272V240C128 231.2 120.8 224 112 224H80C71.16 224 64 231.2 64 240V272zM176 224C167.2 224 160 231.2 160 240V272C160 280.8 167.2 288 176 288H208C216.8 288 224 280.8 224 272V240C224 231.2 216.8 224 208 224H176zM256 272C256 280.8 263.2 288 272 288H304C312.8 288 320 280.8 320 272V240C320 231.2 312.8 224 304 224H272C263.2 224 256 231.2 256 240V272zM80 96C71.16 96 64 103.2 64 112V144C64 152.8 71.16 160 80 160H112C120.8 160 128 152.8 128 144V112C128 103.2 120.8 96 112 96H80zM160 144C160 152.8 167.2 160 176 160H208C216.8 160 224 152.8 224 144V112C224 103.2 216.8 96 208 96H176C167.2 96 160 103.2 160 112V144zM272 96C263.2 96 256 103.2 256 112V144C256 152.8 263.2 160 272 160H304C312.8 160 320 152.8 320 144V112C320 103.2 312.8 96 304 96H272z"
                                />
                                <span>{{ jobInfo.address }}</span>
                              </div>
<!--                              <div v-if="jobInfo.experience" class=" svg-and-text">-->
<!--                                <svg-component-->
<!--                                    width="13px"-->
<!--                                    height="13px"-->
<!--                                    color="#8492a6"-->
<!--                                    viewBox="0 0 512 512"-->
<!--                                    d="M502.6 278.6l-96 96C400.4 380.9 392.2 384 384 384s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L402.8 288h-293.5l41.38 41.38c12.5 12.5 12.5 32.75 0 45.25C144.4 380.9 136.2 384 128 384s-16.38-3.125-22.62-9.375l-96-96c-12.5-12.5-12.5-32.75 0-45.25l96-96c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224h293.5l-41.38-41.38c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l96 96C515.1 245.9 515.1 266.1 502.6 278.6z"-->
<!--                                />-->
<!--                                <span>{{ jobInfo.experience }} of experience</span>-->
<!--                              </div>-->
<!--                              <div v-if="jobInfo.education" class=" svg-and-text">-->
<!--                                <svg-component-->
<!--                                    width="13px"-->
<!--                                    height="13px"-->
<!--                                    color="#8492a6"-->
<!--                                    viewBox="0 0 512 512"-->
<!--                                    d="M623.1 136.9l-282.7-101.2c-13.73-4.91-28.7-4.91-42.43 0L16.05 136.9C6.438 140.4 0 149.6 0 160s6.438 19.65 16.05 23.09L76.07 204.6c-11.89 15.8-20.26 34.16-24.55 53.95C40.05 263.4 32 274.8 32 288c0 9.953 4.814 18.49 11.94 24.36l-24.83 149C17.48 471.1 25 480 34.89 480H93.11c9.887 0 17.41-8.879 15.78-18.63l-24.83-149C91.19 306.5 96 297.1 96 288c0-10.29-5.174-19.03-12.72-24.89c4.252-17.76 12.88-33.82 24.94-47.03l190.6 68.23c13.73 4.91 28.7 4.91 42.43 0l282.7-101.2C633.6 179.6 640 170.4 640 160S633.6 140.4 623.1 136.9zM351.1 314.4C341.7 318.1 330.9 320 320 320c-10.92 0-21.69-1.867-32-5.555L142.8 262.5L128 405.3C128 446.6 213.1 480 320 480c105.1 0 192-33.4 192-74.67l-14.78-142.9L351.1 314.4z"-->
<!--                                />-->
<!--                                <span>{{ jobInfo.education }}</span>-->
<!--                              </div>-->
                              <div class="svg-and-text">
                                <svg-component
                                    width="13px"
                                    height="13px"
                                    color="#8492a6"
                                    viewBox="0 0 512 512"
                                    d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"
                                />
                                <span>{{ jobInfo.remote === 1 ? $t('jobBoard.remote') : `${jobInfo.city}, ${jobInfo.state}` }}</span>
                              </div>
                            <wage-tag :job="jobInfo"></wage-tag>
                          </div>
                      </div>
                    </div>
                    <Banner code="job_board_description_top" style="margin-bottom: 1em;"/>
                    <div class="job-description-wrapper" v-html="jobInfo.htmldescription"></div>
                </div>
                <div class="acuspire-job-footer">
                    <a :href="applyUrlInfo"
                       @click="clickedApply"
                       class="acuspire-btn-apply bottom-apply"
                       :class="subsystem === 'joboffer' && 'joboffer-btn-apply'">
                        <span>{{ $t("jobDescription.applyVia") }}</span>
                    </a>
                </div>
              <Banner code="job_board_description_bottom"/>
            </div>
            <div v-else class="noMatches-container">
                <router-link
                    :to="{
                        name: 'Job-Board',
                        params: {
                            returnPage: page,
                        },
                    }"
                    class="acuspire-job-board-go-back noResults"
                    :class="subsystem === 'joboffer' && 'joboffer-go-back'"
                >
                    {{ $t("jobDescription.back") }}
                </router-link>
                <div class="noMatches-content">
                    <div class="noMatches-heart">
                        <img
                            :src="redHeartURL + '/assets/img/red.png'"
                            alt=":("
                        />
                    </div>
                    <p class="noMatches-lbl">
                        {{ $t("jobDescription.noLoad1") }} 😢
                        {{ $t("jobDescription.noLoad2") }}
                        later
                    </p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import WageTag from "../components/WageTag.vue";
import {mapActions, mapState} from "vuex";
import SvgComponent from "../components/SvgComponent";
import JobViewTracking from "@/composables/JobViewTracking";
import Banner from "@/components/Banner.vue";

export default {
    components: { Banner, SvgComponent, WageTag },
    name: "JobDescription",

    props: ["page"],

    data() {
        return {
            isLoading: false,
            company: "Acuspire",
            redHeartURL: "https://static.acuspire.ai/",
            jobInfo: {},
            applyUrlInfo:'',
            loadErrorInfo: null,
            pageInfo: null,
            postedAtInfo: null,
            isNewLoading: false,
        };
    },

    async mounted() {
        if(!this.job && !sessionStorage.getItem('properties')){
          this.isNewLoading = true;
          await this.getDescriptionData({email_code: this.$route.params.emailCode, locale: this.$i18n.locale})
          this.setAllStates(this.job, this.applyUrl, this.loadError, this.page, this.postedAt);
          this.isNewLoading = false;
        }else if(!this.job){
          const {job, applyUrl, loadError, page, postedAt } = JSON.parse(sessionStorage.getItem('properties'));
          this.setAllStates(job, applyUrl, loadError, page, postedAt);
        } else{
          sessionStorage.setItem('properties', JSON.stringify({
            job: this.job,
            applyUrl: this.applyUrl,
            loadError: this.loadError,
            emailCode: this.emailCode,
            page: this.page,
            postedAt: this.postedAt,
          }));
          this.setAllStates(this.job, this.applyUrl, this.loadError, this.page, this.postedAt);
        }
        window.scrollTo(0, window.acuspireWidgetTop);
    },

    computed: {
      ...mapState( ["subsystem", "pageReferrer"]),
      ...mapState('jobDetail',[ "emailCode", "job","applyUrl","loadError", "postedAt"]),
      ...mapState('subsystemData', ["theme"]),
        subsystemStyles: function() {
            return {
                "--btn-primary-colour": this.theme["btn"],
                "--btn-hover-colour": this.theme["hover"],
            };
        },
    },

    methods: {
      ...mapActions('jobDetail', ['getDescriptionData']),

      clickedApply() {
        // if we have a job_id, we can track the apply event
        const job_id = this?.jobInfo?.job_id;
        job_id && JobViewTracking.applied(job_id)
      },

      goBack(){
        this.isLoading = true;
        if(this.pageReferrer === ''){
          this.$router.push({
            name: 'Job-Board',
            params: {
              returnPage: this.page,
            },
          });
        }else{
          window.history.back();
        }
          this.isLoading = false;
      },
      setAllStates(job, applyUrl, loadError, page, postedAt){
        this.jobInfo = job;
        this.applyUrlInfo = applyUrl;
        this.loadErrorInfo = loadError;
        this.pageInfo = page;
        this.postedAtInfo = postedAt
      }
    },
};
</script>

<style lang="scss" scoped>

.description{
  display: flex;
  flex-direction: column;
  align-items: center;
  .job-card-wrapper{
    margin-top: 20px;
    width: 100%;

    .job-card-inner-wrapper{
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
      border-radius: 0.25rem!important;
      border: 0!important;
      margin-bottom: 30px;

      .job-card-inner-top{
        display: flex;
        .back-button{
          border: none;
          cursor: pointer;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          margin-right: 8px;
          border-radius: 5px;
          padding:0;
        }
      }

      .job-card-top-section {
        width: 100%;


        .job-title-posted-time{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          flex: 9;

          .job-logo{
            margin-right: 10px;
            max-height: 75px;
            max-width: 140px;
          }

          .job-title{
            color: #000;
            font-size: 25px;
            margin-right: 10px;
            font-weight: 700;
            text-align: left;
          }

          .posted-job-time{
            color: #8492a6;
            font-size: 12px;
          }

        }
        .job-desc-apply-button{
          flex: 2;
        }


      }

      .job-company{
        color: #8492a6;
        font-size: 16px;
        font-weight: 600;
      }

      .job-card-summary-section{
        color: #8492a6;
        border-top: 1px solid #eee;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 13px;
        margin: 10px 0 0 0;
        overflow: auto;
        padding: 10px 0 0 0;
        div{
          margin-right: 25px;
          margin-bottom: 2px;
        }
      }
    }
  }
  .job-description-wrapper{
    width: 100%;
    overflow-x: hidden;

      ::v-deep h1{
        font-size: 20px !important;
        font-weight: 600;
        margin: 0.83em 0;
      }

      ::v-deep h2{
        font-size: 20px !important;
        font-weight: 600;
        margin: 0.83em 0;
      }
  }
}

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}


@media (min-width: 1025px){
  .description {
    .job-card-inner-wrapper {
      padding: 1.5rem;
    }
  }
}

@media (max-width: 1024px){
  .description {
    .job-card-inner-wrapper {
      padding: 22px 14px;
    }
  }
}

@media (min-width: 613px) {
    .job-title-posted-time-apply-button{
      display: flex;
    }

    .job-card-top-section{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: column;
    }

    .job-description-wrapper {
      padding: 0 24px;
    }
}

@media (max-width: 612px) {
    .job-title-posted-time-apply-button{
      display: flex;
      flex-direction: column;
      .job-desc-apply-button{
        margin: 10px 0;
      }
    }
    .job-card-top-section{
      display: flex;
      flex-direction: column;
    }
}


#acuspire-job-description {
    body * {
        font-family: "Lato", "Arial", "Verdana", sans-serif;
    }


    .acuspire-btn-apply {
        color: #fff;
        border-radius: 5px;
        font-size: 17px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        font-family: Arial, Verdana, sans-serif;
        height: 100%;
        min-width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 3px;
        border: 1px solid white;
        img{
          display: none;
          height: 30px!important;
        }

    }

    .acuspire-job-footer {
      display: flex;
      justify-content: center;
      align-items: center;
        margin: 0 auto 0 auto;
        padding-top: 20px;
        text-align: center;
    }

    .back-button, .acuspire-btn-apply {
      background-color: var(--btn-primary-colour);
    }

    .back-button:hover{
      background-color: var(--btn-hover-colour);
    }


    // Custom colours
    .acuspire-btn-apply:hover {
      // border: 1px solid var(--btn-hover-colour);
      background-color: var(--btn-hover-colour);
      height: 100%;
      // span{
      //   display: none;
      // }
      // img{
      //   display: block;
      // }
    }
    .noResults {
        width: 7%;
    }

    a {
        text-decoration: none;
    }

    h1 {
        text-align: center;
        max-width: 83%;
        margin-left: 0.7em;
    }

    h2 {
        margin-bottom: 0;
    }

    .noMatches-container {
        width: 100%;
        margin-top: 2vh;
    }

    .noMatches-content {
        margin-top: 10vh;
    }

    .noMatches-lbl {
        float: right;
        text-align: left;
        color: #9b9b9b;
        font-size: 20px;
        width: 66%;
    }

    .noMatches-heart {
        width: 33%;
        float: left;
        text-align: center;
    }

    .bottom-apply{
      margin-bottom: 20px;
    }
}

.svg-and-text{
  display: flex;
  align-items: center;
   & > span{
     margin-left: 5px;
   }
}

</style>
