<template>
  <div id="acuspire-jobs-widget">
    <Loader v-if="isLoading" :text="$t('jobBoard.loading')"/>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import language from "@/composables/Language";
import { mapActions, mapState } from "vuex";
import Loader from "@/components/Loader.vue";

export default {
  components: { Loader },
  props: {
    type: {
      type: String,
      required: false
    }
  },
  async mounted() {
    this.setLanguage();
    await this.getSubsystemData();
  },

  methods: {
    ...mapActions('subsystemData', ['getSubsystemData']),
    setLanguage() {
      this.$i18n.locale = language();
    }
  },
  computed: {
    ...mapState('subsystemData', ['isLoading'])
  }
};
</script>

<style lang="scss">

.acuspire-previous {
    margin-right: 1.5vw;
    background:var(--btn-primary-colour);
}

#acuspire-jobs-widget {
  .small-loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #515357;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 85px;
    left: 50%;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

#acuspire-jobs-widget {
  position: relative;
  font-family: "Lato", "Arial", "Verdana", sans-serif;
}
</style>
