<template>
  <div class="acuspire-designations-search" style="">
    <h1 v-if="headerText">{{ headerText }}</h1>
    <p v-if="description">{{ description }}</p>
    <form v-on:submit.prevent="submitQuery" class="acuspire-search-container container-fluid">
      <div :class="systemCode === 'joboffer' ? 'joboffer-search-bar' :'row'">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4" :class="systemCode === 'joboffer' && 'joboffer-search-job-wrap'">
          <div class="acuspire-search-wrap">
            <input
              v-model="searchVal"
              class="acuspire-search acuspire-search-text search-job"
              :class="systemCode === 'joboffer' && 'joboffer-search-job'"
              type="text"
              size="30"
              maxlength="50"
              :placeholder="$t('searchBar.designationInput')"
            />
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4" :class="systemCode === 'joboffer' && 'joboffer-btn-search-wrap'">
          <button class="acuspire-search acuspire-btn-search" type="submit" title="search">
            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <svg-component
                class="job-search-icon"
                v-if="systemCode === 'joboffer' && !loading"
                width="14px"
                height="14px"
                viewBox="0 0 512 512"
                d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"
            />
            <span v-else-if="!loading" >
            {{ $t("searchBar.designationSearch") }}
            </span>
          </button>
        </div>
      </div>
    </form>

    <div v-if="designationResults && designationResults.length > 0" class="designation-results">
      <ul>
        <li v-for="result in designationResults" :key="result.id" class="designation-item">
          <h3>{{ result.name }}</h3>
          <p v-if="result.company">{{ $t("designations.company") }}: {{ result.company }}</p>
          <p v-if="result.city || result.province">
            {{ $t("designations.location") }}: {{ result.city }}{{ result.city && result.province ? ', ' : '' }}{{ result.province }}
          </p>
          <p v-if="result.ep_designation">{{ $t("designations.epDesignation") }}: {{ result.ep_designation }}</p>
          <p v-if="result.ep_type">{{ $t("designations.epType") }}: {{ result.ep_type }}</p>
          <p v-if="result.ep_status">{{ $t("designations.epStatus") }}: {{ result.ep_status }}</p>
        </li>
      </ul>
    </div>
    <div v-else-if="designationResults && designationResults.length === 0" class="no-results">
      <p>{{ $t("designations.noResults") }}</p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  components: { },
  props: {
    jobBoardWidgetUrl: {
      type: String,
      required: false
    },
    systemCode: {
      type: String,
      required: false
    },
    lang: {
      type: String,
      required: false
    },
    headerText: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    }
  },
  data() {
      return {
        loading: false,
        searchVal: '',
        lastName: '',
      }
    },
  async mounted() {
    this.$i18n.locale = this.lang === 'fr' ? 'fr' : 'en';
  },
  methods: {
    ...mapMutations(["pageReferrerStatus"]),
    ...mapMutations('jobs', ["cityStatus", "jobTitleStatus", "designationStatus"]),
    ...mapActions('jobs', ['getDesignations']),
    submitQuery() {
      if (this.searchVal === "") return;
      this.loading = true;
      this.designationStatus(this.searchVal);
      this.getDesignations().finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapState(['pageReferrer']),
    ...mapState('jobs', ['designationResults']),
    ...mapState(["subsystem"]),
  }
};
</script>

<style scoped lang="scss">
  .acuspire-search-text {
    border-radius: 0.5rem;
    height: 2.625em;
    padding-left: 0.625em;
    border: 1px solid #d6d6d6;
    width: 17.5em;
    min-width: 12.5em;
    font-family: "Lato", "Arial", "Verdana", sans-serif;
    flex: 1 1 auto;
    outline-color: var(--acuspire-job-block-outline-colour, var(--acuspire-job-block-primary-colour, darkgreen));
    outline-offset: 0;
    margin-bottom:10px;
  }
  .acuspire-btn-search {
    color: var(--acuspire-job-block-btn-text-colour, white);
    border-radius: 3px;
    height: 2.625em;
    padding: 0.375em 0.75em;
    // font-size: 14px;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    border: 1px solid var(--acuspire-job-block-btn-primary-colour, var(--acuspire-job-block-primary-colour, darkgreen));
    min-width: fit-content;
    //max-width: 300px;
    background-color: var(--acuspire-job-block-btn-primary-colour, var(--acuspire-job-block-primary-colour, darkgreen));
    font-family: "Lato", "Arial", "Verdana", sans-serif;
    flex: 1;
    white-space: nowrap;
    cursor:pointer;
    &:hover {
      background-color: var(--acuspire-job-block-btn-hover-colour, var(--acuspire-job-block-secondary-colour, white));
      color: var(--acuspire-job-block-btn-text-hover-colour, var(--acuspire-job-block-primary-colour, darkgreen));
      border-color: var(--acuspire-job-block-btn-text-hover-colour, var(--acuspire-job-block-primary-colour, darkgreen));;
    }
    margin-bottom:10px;
  }
  .designation-results {
    margin-top: 10px;
    padding: 10px;
    background-color: var(--acuspire-job-block-background-colour, #f5f5f5);
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    ul {
      list-style-type: none;
      padding: 0;
      margin-top:0;
      margin-bottom:0;
    }

    .designation-item {
      margin-bottom: 10px;
      padding: 15px;
      background-color: white;
      border-radius: 3px;
      border: 1px solid var(--acuspire-job-block-border-colour, #e0e0e0);

      h3 {
        color: var(--acuspire-job-block-primary-colour, darkgreen);
        margin-top: 0;
        margin-bottom: 10px;
      }

      p {
        margin: 5px 0;
        color: var(--acuspire-job-block-text-colour, #333);
      }
    }
  }
  .no-results {
    margin-top: 20px;
    padding: 15px;
    background-color: var(--acuspire-job-block-background-colour, #f5f5f5);
    border-radius: 5px;
    text-align: center;
    color: var(--acuspire-job-block-text-colour, #333);
  }
</style>