// maybe make a class for this
// should be a singleton
// should allow for the following methods:
// - seen(job_id)
// - hovered(job_id)
// - viewed(job_id)
// - applied(job_id)
// - results(): {seen: [], similar: []}
// - reset()

class JobViewTracking {
  // events can be an array of objects with the following properties:
  // - type: string (seen, hovered, viewed, applied)
  // - job_id: string
  // - timestamp: number?
  static #instance = null;
  #events = [];
  #version = 1;
  #localStorageEventsKey = 'acuspire_job_view_events';
  #lastLoadedOrSaved = undefined;

  constructor() {
    // If an instance already exists, return it (singleton).
    if (JobViewTracking.#instance) {
      return JobViewTracking.#instance;
    }
    JobViewTracking.#instance = this;
    this.#events = [];
  }

  #addEvent(type, job_id) {
    if (!job_id) return;
    // Load from local storage
    this.#loadFromLocalStorage();
    // If the event already exists within the past three seconds, treat it as if the event has only just occurred.
    const threeSecondsInMs = 3 * 1000;
    // We know that events are stored in order of occurrence, so we can iterate backwards.
    // And (because of this function) we know that there is at most one event per job_id per type
    // within the past three seconds, so we can stop iterating after finding it.
    const now = Date.now();
    const minTimestamp = now - threeSecondsInMs;
    for (let i = this.#events.length - 1; i >= 0; i--) {
      const event = this.#events[i];
      if (event.timestamp < minTimestamp) {
        break;
      }
      if (event.job_id === job_id && event.type === type) {
         this.#events.splice(i, 1);
         break;
      }
    }

    // Only keep the last 5000 events (~1MB)
    if (this.#events.length > 5000) {
      this.#events = this.#events.slice(this.#events.length - 5000);
    }

    // Add the event to #events
    this.#events.push({
      type,
      job_id,
      timestamp: Date.now()
    });
    // Save to local storage
    this.#saveToLocalStorage();
  }


  // eslint-disable-next-line no-dupe-class-members
  #saveToLocalStorage() {
    const events = JSON.stringify({
      version: this.#version,
      events: this.#events
    })
    try {
      // Try saving to local storage
      localStorage.setItem(this.#localStorageEventsKey, events);
      // If we were successful, update the last loaded or saved events.
      this.#lastLoadedOrSaved = events;
    } catch (err) {
      // Catch the QuotaExceededError and throw away the least important events
      // until the new event fits (this means that maybe #events should be a priority queue).
      // so that we can keep older applications, but throw away newer views.
      if (err.name !== 'QuotaExceededError') {
        throw err;
      }
      if (this.#events.length <= 10) {
        // If we have less than 10 events, we can't throw any away.
        // So we'll just throw an error.
        throw err;
      }
      // Remove half the events (keeping the newest)
      this.#events = this.#events.slice(Math.floor(this.#events.length / 2));
      // Try saving again
      this.#saveToLocalStorage();
    }
  }

  // eslint-disable-next-line no-dupe-class-members
  #loadFromLocalStorage() {
    const jsonData = localStorage.getItem(this.#localStorageEventsKey);
    if (jsonData === this.#lastLoadedOrSaved) {
      // We have the most up-to-date data, so we don't need to load it again.
      return;
    }
    const data = JSON.parse(jsonData);
    if (!data) {
      this.#events = [];
      return;
    }
    if (data.version !== this.#version) {
      // Convert previous version data to new version data.
    }
    this.#events = data?.events ?? [];
  }

  seen(job_id) {
    this.#addEvent('seen', job_id);
  }

  hovered(job_id) {
    this.#addEvent('hovered', job_id);
  }

  viewed(job_id) {
    this.#addEvent('viewed', job_id)
  }

  applied(job_id) {
    this.#addEvent('applied', job_id);
  }

  results(inputEventWeights) {
    this.#loadFromLocalStorage();

    const jobWeights = {
      // Jobs to derank in search results
      derank: {},
      // Jobs to use to find similar jobs
      similar: {}
    };

    // If there are no events, return we can return immediately.
    if (this.#events.length === 0) {
      return jobWeights;
    }

    const performDecay = ({decay, weight, x, b}) => {
      return (weight / ( (1/ decay) * x + b));
    }

    // We'll weight the events based on the time they occurred.
    const timeWeightingFunction = (timestamp, maxTimestamp) => {
      // We want to weight the events based on the time they occurred.
      if (maxTimestamp < 0) return 1;
      const oneHourInMs = 60 * 60 * 1000;

      let rankings = [
        {
          weight: 0.25,
          decayMs: oneHourInMs / 2 // 30 minutes
        },
        {
          weight: 0.75,
          decayMs: 60 * 24 * oneHourInMs // 60 days
        }
      ];

      // The formula we'll use for each is (a / (m * x + b)) where:
      let x = Math.max((maxTimestamp - timestamp), 0);
      let b = 1;
      let m = ({decayMs}) =>  1 / (decayMs);
      let a = ({weight}) => weight;

      return rankings.reduce((acc, rank) => {
        return acc + (a(rank) / (m(rank) * x + b));
      }, 0);
    }

    // All events count towards the seen weight because we want to show jobs
    // the user has already interacted with less often than jobs they
    // haven't interacted with. For example, if a user has seen a job or hovered over it,
    // but didn't view or apply to it, they likely weren't interested in it. The goal is to
    // show them jobs they're more likely to be interested in.
    const defaultEventWeights = {
      seen: 10,
      hovered: 5,
      viewed: 50,
      applied: 250,
    };
    if (!inputEventWeights || typeof inputEventWeights !== 'object') {
      inputEventWeights = {};
    }

    const eventWeights = {...defaultEventWeights, ...inputEventWeights};


    // Find the min and max timestamps for the events.
    let maxTimestamp = 0;
    for (const event of this.#events) {
      if (event?.timestamp && event.timestamp > 0) {
        maxTimestamp = Math.max(maxTimestamp, event.timestamp);
      }
    }

    // sort events by timestamp, most recent event first
    const events = this.#events.toSorted((a, b) => b.timestamp - a.timestamp);

    let eventsSeen = {
      seen: 0,
      hovered: 0,
      viewed: 0,
      applied: 0
    };

    // Calculate the weights for each job.
    for (const event of events) {
      let weight = eventWeights[event.type];
      weight *= timeWeightingFunction(event.timestamp, maxTimestamp);

      if (['viewed', 'applied'].includes(event.type)) {
        // We reduce the weight of the event based on the number of events that have occurred since.
        // This is because we want to give more weight to the most recent events and not have users
        // get stuck into one type of job because they viewed a bunch of similar ones a long time.
        let w = jobWeights.similar[event.job_id] ?? 0;
        const pd = performDecay({decay: 10, weight: 1, x: eventsSeen[event.type] ?? 0, b: 1})
        w += (weight * pd);
        jobWeights.similar[event.job_id] = w;
      }
      // everything interacted with gets added to the derank list
      jobWeights.derank[event.job_id] = (jobWeights.derank[event.job_id] ?? 0) + weight;
      eventsSeen[event.type]++;
    }

    // round to 2 decimal places by multiplying by 100 and converting to integer
    for (const key of ['derank', 'similar']) {
      for (const job in jobWeights[key]) {
        jobWeights[key][job] = Math.round(jobWeights[key][job] * 100);
      }
    }


    // Take top 15 similar jobs after performing weightings
    jobWeights.similar = Object.fromEntries(
      Object.entries(jobWeights?.similar ?? {})
        // sort by weight desc
        .toSorted((a, b) => b[1] - a[1])
        // take top 15
        .slice(0, 15)
    );
    // Take top 1000 job derankings after performing weightings
    jobWeights.derank = Object.fromEntries(
      Object.entries(jobWeights?.derank ?? {})
        // sort by weight desc
        .toSorted((a, b) => b[1] - a[1])
        // take top 1000
        .slice(0, 1000)
    );


    return jobWeights;
  }

  reset() {
    this.#events = [];
    this.#saveToLocalStorage();
  }
}

// create and export a singleton instance of JobViewTracking
export default new JobViewTracking();
