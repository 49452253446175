import Vue from "vue";
import Vuex from "vuex";
import jobs from "./modules/jobs";
import jobDetail from "./modules/jobDetail";
import subsystem from "../composables/Subsystem";
import subsystemData from './modules/subsystemData';

Vue.use(Vuex);

export default new Vuex.Store({
    namespaced:true,
    state:{
        subsystem: subsystem(),
        pageReferrer: '',
    },
    modules:{
        jobs,
        jobDetail,
        subsystemData
    },
    mutations:{
        pageReferrerStatus(state){
            state.pageReferrer = document.URL
        }
    }
});
