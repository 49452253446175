import http from "../../http-common";
import JobViewTracking from "@/composables/JobViewTracking";

export default{
    namespaced: true,
    state: {
        isLoading: true,
        descriptionURL: '',
        emailCode: '',
        postedAt: null,
        loadError: false,
        applyUrl: "",
        jobDesc:"",
        job:null,
        locale: '',
    },
    mutations:{
        descriptionURLStatus(state) {
            state.descriptionURL = `/search/job?code=${state.emailCode}`;
        },
        formatDate(state) {
            let date = state.job.created_at;
            if(date) state.postedAt = date.split(" ")[0];
        },
    },
    actions:{
        async getJob({commit, state}) {
            try {
                commit('descriptionURLStatus');
                const json = await http.get(state.descriptionURL);
                state.job = json.data.job;

                JobViewTracking.viewed(state?.job?.job_id)
                state.applyUrl = this.state.subsystemData.appUrl + `/job-board/${state.job.job_id}/apply` + (state.locale !== `en` ? `?lang=fr` : ``);
            } catch (error) {
                state.loadError = true;
                state.isLoading = false;
            }
        },

        async getDescriptionData({dispatch, commit, state},{email_code, locale}) {
            state.emailCode = email_code;
            state.locale = locale;
            await dispatch('getJob');
            commit('formatDate' );
        },

    },
}
