<template>
    <div class="loader-container">
      <div class="loading-text" v-if="text">
        <span style="color: #0000">{{ellipsis}}</span>{{text}}{{ellipsis}}
      </div>
      <div class="small-loader-container">
        <div class="small-loader"></div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    text: {
      type: String,
      required: false
    },
    ms: {
      type: Number,
      required: false,
      default: 500
    }
  },
  data() {
    return {
      intervalId: null,
      ellipsisCount: 0,
    };
  },
  mounted() {
    this.intervalId = setInterval(() => {
      this.ellipsisCount = (this.ellipsisCount + 1) % 4;
    }, this.$props.ms);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  computed: {
    ellipsis() {
      return '.'.repeat(this.ellipsisCount);
    }
  }
};
</script>


<style scoped lang="scss">
#acuspire-jobs-widget {
  .loader-container {
    position: relative;
  }
  .small-loader-container {
    position: relative;
    height: calc(60px + /* margin */ 8px);
  }
  .loading-text {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: #515357;
  }
  .small-loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #515357;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 4px;
    left: calc(50% - (60px/ 2));
    box-sizing: border-box;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
</style>