<template>
  <div id="acuspire-job-board" :style="subsystemStyles"><!--  class="container" -->
    <search-bar id="acuspire-search-bar" :isLoading="isLoading"/>
<!--    fix jobs total....-->
<!--    posted on-->
    <div>
      <div v-if="jobs.length > 0 && searched" class="jobs-container container-fluid">
        <p class="results">
          {{ displayCurrResults }} {{ $t("jobBoard.of") }}
          {{ displayRangeResults }}
          {{ $t("jobBoard.jobs") }}
        </p>
        <Banner v-if="jobs.length > 0" code="job_board_list_top"/>
        <Job
          v-for="job in jobs"
          :ref="'job-' + job.email_code"
          :key="job.email_code"
          :job="job"
          :meta="meta"
          :activePage="activePage"
          :isLoading="loadingJob === job.email_code"
        />
        <Banner v-if="jobs.length > 0" code="job_board_list_bottom" style="margin-bottom: 1.5em;"/>

        <Paginate
          :totalRangeResults="totalRangeResults"
          :totalResults="totalResults"
          :activePage="activePage"
        />
      </div>

      <div v-else-if="jobs.length < 1 && searched" class="noMatches-container">
        <div class="noMatches-heart">
          <img :src="imgHostingURL + 'assets/img/red.png'" alt="No results :(" />
        </div>
        <p class="noMatches-lbl">
          {{ $t("noMatches.heading") }} 😢
          <br />
          <small class="sub">{{ $t("noMatches.msg") }}</small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Job from "@/components/Job.vue";
import SearchBar from "@/components/SearchBar.vue";
import Paginate from "@/components/Paginate.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import Banner from "@/components/Banner.vue";

export default {
  name: "JobBoard",
  props: ["returnPage", "emailCodeToScrollTo"],
  components: {
    Banner,
    Job,
    SearchBar,
    Paginate
  },

  data() {
    return {
      imgHostingURL: "https://static.acuspire.ai/", // red heart
      pageSize: 25,
      loadingJob: -1, // no job should have an email_code of -1
    };
  },
  created() {
    // Remove hash from the URL.
    // history.replaceState("", document.title, window.location.pathname + window.location.search);
  },

  async mounted() {
      await this.checkReturnPage();
      const jobtitle_s = this?.$route?.query?.jobtitle_s;
      if (jobtitle_s) {
        this.jobTitleStatus(jobtitle_s);
      }
      const city_s = this?.$route?.query?.city_s;
      if (city_s) {
        this.cityStatus(city_s);
      }
      await this.getJobData({performScroll: false})
      this.returned = false;
  },

  watch: {
    emailCodeToScrollTo: {
      handler(val) {
        if (!val) return;
        this.$nextTick(() => {
          const job = this.$refs[`job-${val}`];
          if (Array.isArray(job)) {
            if (job.length > 0) {
              job?.[0]?.$el.scrollIntoView({block: "center", inline: "center"});
            }
          } else {
            job?.$el.scrollIntoView({block: "center", inline: "center"});
          }
        });
      },
      immediate: true
    }
  },

  async beforeRouteLeave(to, from, next) {
    const email_code = to?.params?.emailCode ?? -1;
    if (email_code === -1) {
      // TODO: Add a toast message here or something indicating failure to the user
      next(false)
    }
    this.loadingJob = email_code;
    try {
      await this.getDescriptionData({email_code, locale: this.$i18n.locale})
      next();
    } catch {
      // TODO: Add a toast message here or something indicating failure to the user
      next(false);
    }
  },

  computed: {
    ...mapState('jobs',[
      'isLoading','jobs', "totalRangeResults", "totalResults", "activePage", "meta", "searched", "displayCurrResults", "displayRangeResults"
    ]),
    ...mapState('subsystemData', ['theme']),
    subsystemStyles() {
      return {
        "--btn-primary-colour": this.theme["btn"],
        "--btn-hover-colour": this.theme["hover"]
      };
    },
  },

  methods: {
    ...mapActions('jobs',["checkReturnPage", "getJobData", "movePage", "chosenPage"]),
    ...mapMutations('jobs', ['jobTitleStatus', 'cityStatus']),
    ...mapActions('jobDetail', ['getDescriptionData'])
  }
};
</script>

<style lang="scss">
/* Styles are here to prevent overrides */
#acuspire-job-board {
    /* Start Bootstrap */
    a{background-color:transparent;}
    a:active,a:hover{outline:0;}
    img{border:0;}
    button,input{color:inherit;font:inherit;margin:0;}
    button{overflow:visible;}
    button{text-transform:none;}
    button{-webkit-appearance:button;cursor:pointer;}
    button[disabled]{cursor:default;}
    button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}
    input{line-height:normal;}
    @media print{
        *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;-webkit-box-shadow:none!important;box-shadow:none!important;}
        a,a:visited{text-decoration:underline;}
        a[href]:after{content:" (" attr(href) ")";}
        a[href^="#"]:after{content:"";}
        img{page-break-inside:avoid;}
        img{max-width:100%!important;}
        h3,p{orphans:3;widows:3;}
        h3{page-break-after:avoid;}
    }
    *{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
    :after,:before{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
    body{font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;font-size:14px;line-height:1.42857143;color:#333;background-color:#fff;}
    button,input{font-family:inherit;font-size:inherit;line-height:inherit;}
    a{color:#337ab7;text-decoration:none;}
    a:focus,a:hover{color:#23527c;text-decoration:underline;}
    a:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
    img{vertical-align:middle;}
    h3,h5{font-family:inherit;font-weight:500;line-height:1.1;color:inherit;}
    h3{margin-top:20px;margin-bottom:10px;}
    h5{margin-top:10px;margin-bottom:10px;}
    h3{font-size:24px;}
    h5{font-size:14px;}
    p{margin:0 0 10px;}
    p.posted-date {margin: 5px 0 9px 0;}
    .container-fluid{padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
    .row{margin-right:-15px;margin-left:-15px;}
    .col-md-4,.col-md-6,.col-md-8,.col-sm-12,.col-sm-5,.col-sm-7,.col-xs-12,.col-xs-4,.col-xs-8{position:relative;min-height:1px;padding-right:15px;padding-left:15px;}
    .col-xs-12,.col-xs-4,.col-xs-8{float:left;}
    .col-xs-12{width:100%;}
    .col-xs-8{width:66.66666667%;}
    .col-xs-4{width:33.33333333%;}
    @media (min-width:1200px)
    {
        .col-lg-3{width:25%!important}
        .col-lg-4{width:33.33333333%!important;}
        .col-lg-6{width:50%!important;}
        .col-lg-9{width:75%!important}
    }
    @media (min-width:768px){
        .col-sm-12,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7{float:left;}
        .col-sm-7{width:58.33333333%;}
        .col-sm-6{width:50%;}
        .col-sm-5{width:41.66666667%;}
        .col-sm-4{width:33.33333333%;}
        .col-sm-12{width:100%;}
    }
    @media (min-width:992px){
        .col-md-4,.col-md-6,.col-md-8{float:left;}
        .col-md-8{width:66.66666667%;}
        .col-md-6{width:50%;}
        .col-md-4{width:33.33333333%;}
    }

    @media(max-width:767px) {
        .acuspire-job-meta-apply{
            display: flex;
            flex-direction: column-reverse;
            margin: 10px 0;
        }
    }
    .container-fluid:after,.container-fluid:before,.row:after,.row:before{display:table;content:" ";}
    .container-fluid:after,.row:after{clear:both;}

  /* End Bootstrap */

  // Custom colours
  .acuspire-btn-search,
  .acuspire-btn-apply {
    background-color: var(--btn-primary-colour);
  }

  a.acuspire-job-description:hover {
    color: var(--btn-hover-colour);
  }

  .acuspire-btn-apply:hover,
  .acuspire-btn-search:hover {
    background-color: var(--btn-hover-colour);
  }

  // Search Bar
  .acuspire-search-container {
    margin-top: 1vh;
    align-items: baseline;
  }

  .acuspire-search-text {
    border-radius: 0.5rem;
    height: 42px;
    padding-left: 10px;
    border: 1px solid #d6d6d6;
    width: 100%;
    margin-bottom:8px;
  }

  .acuspire-btn-search {
    color: #fff;
    border-radius: 3px;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    padding: 6px 12px;
    border: 0;
    height: 42px;
    width: 100%;
  }

  // Job
  a {
    text-decoration: none;
    color: #515357;
  }

  a:visited {
    color: #515357;
  }

  h3,
  h5,
  p {
    margin-bottom: 0;
  }

  h3 {
    font-weight: 600;
    font-size: 21px;
    margin-top:0;
    margin-bottom: 7px;
  }

  h5 {
    color: #808080;
  }

  h5 {
    margin-top: 5px;
  }

  p {
    color: #a1a1a1;
    margin-top:10px;
  }

  img.epImg {
    height: auto;
    width: 30px;
    margin: 10px 0 20px 0;
    float:left;
  }

  img.internImg, img.coopImg {
    height: auto;
    width: 100px;
    margin: 10px 0 20px 0;
    float:left;
  }

  p.results {
    color: #636363;
    margin-top:15px;
    margin-bottom:15px;
  }

  .location {
    margin-left: 2.25em;
    border: 1px solid #ddd;
    padding: 2px 6px;
    border-radius: 8px;
    font-size: 12px;
  }

  // Acuspire pagination design
  .acuspire-pagination-container, .joboffer-job-widget-pagination{
    .acuspire-paginate {
      border-radius: 5px;
      cursor: pointer;
      background: transparent;
      border: none;
      font-size: 16px;
      color:#fff;
      background: var(--btn-primary-colour);
      margin:0 2px;
      padding:10px 20px!important; /* Prevent host override */
    }

    //.acuspire-paginate:first-child {
    //  padding-left:0!important;
    //}

    .acuspire-previous,
    .acuspire-next {
      padding-left: 0;
    }

    .acuspire-previous {
      margin-right: 1vw;
      background:var(--btn-primary-colour);
    }

    .acuspire-next {
      margin-left: 1vw;
    }

    .acuspire-paginate.acuspire-active {
      cursor: default;
      text-decoration: none;
    }

    .acuspire-paginate,
    .acuspire-previous,
    .acuspire-next {
      min-width: unset !important;
      max-width: unset !important;
    }

    .acuspire-paginate:hover,
    .acuspire-paginate.acuspire-active {
      background: var(--btn-hover-colour);
      color: #fff;
    }
  }

  .acuspire-jobs-btn {
    color: #fff;
    border-radius: 3px;
    font-size: 17px;
    line-height: 1.5rem;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    padding: 10px 30px;
    font-family: Arial, Verdana, sans-serif;
    border: 0;
    min-width:auto;
    height: 44px;
    float:right;
  }

  .acuspire-jobs-btn:after,
  .acuspire-paginate:after,
  .acuspire-previous:after,
  .acuspire-next:after,
  .acuspire-btn-apply:after,
  .first-apply:after,
  .bottom-apply:after,
  .acuspire-btn-search:after {
    content: none !important; /* Prevent host override */
  }

  .job-description-apply {
      margin-bottom: 15px;
      display: block;
  }

  .jobs-container {
    margin-bottom: 7vh;
  }

  .acuspire-hide-pagination {
    visibility: hidden;
  }

  @media only screen and (max-width: 543px) {
    .search-city,
    .acuspire-btn-search {
      margin-top: 5px;
    }

    .search-city {
      margin-left: 0;
    }

    .acuspire-pagination-container, .joboffer-job-widget-pagination {
      margin-top: 5vh;
      bottom: 2vh;
      text-align: center;
    }
  }

  .acuspire-job-container {
      border-top: 1px solid #eee;
      padding-top: 25px;
      margin-bottom:25px;
  }

    @media screen and (min-width: 767px) {
      .acuspire-job-container {
        display: flex;
        flex-direction: row;
        .acuspire-job-descr-container{
          flex: 4;
        }

        .acuspire-job-meta-apply{
          flex: 1;
          text-align: right;
          margin-left: 40px;

        }
      }
    }

    @media (max-width: 767px) {
      .acuspire-job-container {
        display: flex;
        flex-direction: column;
        .acuspire-job-logo-container{
          margin-bottom: 15px;
        }
      }
    }
}

.noMatches-container {
  width: 100%;
  margin-top: 2vh;
}

.noMatches-lbl {
  float: right;
  text-align: left;
  color: #9b9b9b;
  font-size: 20px;
  width: 66%;
}

.noMatches-heart {
  width: 33%;
  float: left;
  text-align: center;
}

</style>
