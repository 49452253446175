import http from "../../http-common";

export default {
    namespaced: true,
    state: {
        appUrl: "",
        imgHostingURL: "https://app.joboffer.com/",
        isLoading: true,
        theme: {btn: null, hover: null},
        banners: [],
    },
    mutations:{
        setAppUrl(state, {newAppURL}){
            state.appUrl = newAppURL;
        },
        setIsLoading(state, {isLoading}) {
            state.isLoading = isLoading;
        },
        setTheme(state, {newTheme}){
            state.theme.btn = newTheme?.['btn-primary'] ?? null;
            state.theme.hover = newTheme?.['hover'] ?? null;
        },
        setBanners(state, {newBanners}){
            if (Array.isArray(newBanners)) {
                state.banners = newBanners.reduce((acc, banner) => {
                    if (!banner?.code) {
                        return acc;
                    }
                    return {...acc, [banner.code]: banner};
                }, {});
            }
        }
    },
    actions: {
        async getAppUrl({commit}) {
            const params = {
                params: {
                    subsystem: this.state.subsystem,
                },
            };

            const json = await http.get("/subsystem/app_url", params);
            commit('setAppUrl', {newAppURL: json.data.app_url});
        },

        async getSubsystemData({commit}) {
            commit('setIsLoading', {isLoading: true});

            const params = {
                params: {
                    subsystem: this.state.subsystem,
                },
            };
            let isValid = false;
            let sleepTimeMs = 0;
            while (!isValid) {
                // keep retrying with back-off
                if (sleepTimeMs > 0) {
                    await new Promise(resolve => setTimeout(resolve, sleepTimeMs));
                    sleepTimeMs += 500;
                } else {
                    sleepTimeMs = 1000;
                }
                try {
                    const json = await http.get("/subsystem/data", params);
                    if (json.data?.app_url && json.data?.theme && json.data?.banners) {
                        commit('setAppUrl', {newAppURL: json.data.app_url});
                        commit('setTheme', {newTheme: json.data.theme});
                        commit('setBanners', {newBanners: json.data.banners});
                        isValid = true;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            commit('setIsLoading', {isLoading: false});
        }

    }
}
